import React, { FunctionComponent, ReactNode } from "react";

import Container from "./container";
import Logo from "../images/logo-white.svg";
import Instagram from "../images/instagram.svg";
import Facebook from "../images/facebook.svg";
import LinkedIn from "../images/linkedin.svg";
import classes from "./footer.module.css";

interface FooterProps {
    includeContactDetails?: boolean;
}

interface SocialLinkProps {
    icon: ReactNode;
    link: string;
    label: string;
}

const SocialLink: FunctionComponent<SocialLinkProps> = ({ icon, link, label }) => (
    <a
        href={link}
        rel="noopener noreferrer"
        target="_blank"
        aria-label={label}
    >
        {icon}
    </a>
);

const Footer: FunctionComponent<FooterProps> = () => (
    <footer className={classes.pageFooter}>
        <Container>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between'
            }}>
                <p className={classes.copyright}>&copy; 2021 - Praktijk De Leersprong</p>
                <div className={classes.footerLogoContainer}>
                    <Logo style={{ height: "80px" }} />
                </div>

                <div className={classes.socialMediaContainer}>
                    <SocialLink icon={<Instagram style={{ stroke: "currentColor" }} />} link="https://www.instagram.com/praktijkdeleersprong/" label="Open onze instagram pagina" />
                    <SocialLink icon={<Facebook style={{ stroke: "currentColor" }} />} link="https://www.facebook.com/praktijkdeleersprong/" label="Open onze facebook pagina"/>
                    <SocialLink icon={<LinkedIn style={{ stroke: "currentColor" }} />} link="https://www.linkedin.com/company/praktijk-de-leersprong" label="Open onze linkedin pagina"/>
                </div>
            </div>
        </Container>
    </footer>
);

export default Footer;
