/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { FunctionComponent } from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import Header from "./header";
import "./layout.css";
import Footer from "./footer";

interface LayoutProps {
    currentPath: string;
    contactDetailsInFooter?: boolean;
}

const Layout: FunctionComponent<LayoutProps> = ({
    currentPath,
    contactDetailsInFooter = true,
    children,
}) => {
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `);

    return (
        <div
            style={{
                minHeight: "100vh",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <div
                style={{
                    position: "fixed",
                    top: 0,
                    right: 0,
                    left: 0,
                    zIndex: 1,
                }}
            >
                <Header currentPath={currentPath} />
            </div>

            <div
                style={{
                    marginTop: "90px",
                    flex: "1",
                    overflow: "auto",
                }}
            >
                <main>{children}</main>
            </div>
            <Footer />
        </div>
    );
};

export default Layout;
