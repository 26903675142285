import { Link } from "gatsby";
import React, { useState } from "react";
import classNames from "classnames";
import Logo from "../images/logo.svg";
import classes from "./header.module.css";
import Container from "./container";

interface Location {
    name: string;
    path: string;
}

const links: Location[] = [
    { name: "Home", path: "/" },
    { name: "Voorstellen", path: "/voorstellen/" },
    { name: "Bijles", path: "/bijles/" },
    { name: "Remedial teaching", path: "/remedial-teaching/" },
    { name: "Huiswerkbegeleiding", path: "/huiswerkbegeleiding/" },
    { name: "Cito-training", path: "/cito-training/" },
    { name: "Tarieven", path: "/tarieven/" },
    { name: "Contact", path: "/contact/" },
];

interface HeaderProps {
    currentPath: string;
}

const Header = ({ currentPath }: HeaderProps) => {
    const [menuOpen, setMenuOpen] = useState(false);

    return (
        <>
            <div className={classes.accentBar}></div>
            <header className={classes.pageHeader}>
                <Container>
                    <div className={classes.navContainer}>
                        <div className={classes.navBar}>
                            <div className={classes.logoContainer}>
                                <span style={{ marginRight: "1rem" }}>
                                    <Logo style={{ height: "40px" }} />
                                </span>
                            </div>

                            <button
                                className={classes.menuToggle}
                                onClick={() => setMenuOpen(!menuOpen)}
                                aria-label="Open site menu"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    height="24"
                                >
                                    <path d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z" />
                                </svg>
                            </button>
                        </div>

                        <nav
                            className={classNames(classes.links, {
                                [classes.showLinks]: menuOpen,
                            })}
                        >
                            {links.map(link => (
                                <Link
                                    key={link.path}
                                    to={link.path}
                                    className={classNames({
                                        [classes.active]:
                                            currentPath === link.path,
                                    })}
                                >
                                    {link.name}
                                </Link>
                            ))}
                        </nav>
                    </div>
                </Container>
            </header>
        </>
    );
};

export default Header;
